import { render, staticRenderFns } from "./MoleculeBoard.vue?vue&type=template&id=112b69c5&"
import script from "./MoleculeBoard.vue?vue&type=script&lang=js&"
export * from "./MoleculeBoard.vue?vue&type=script&lang=js&"
import style0 from "./MoleculeBoard.vue?vue&type=style&index=0&id=112b69c5&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports