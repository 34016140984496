<template>
  <div class="page-wrapper">
    <div class="page-body">
      <div class="container-xl">
        <MoleculeBoard
          :title="$title"
          :loading="loading"
          :floors="board.floors"
          :properties="board.properties"
          :section-names="board.sectionNames"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'

import MoleculeBoard from '@/components/MoleculeBoard'

export default {
  title: vm => vm.$t('pages.board.title'),

  components: {
    MoleculeBoard,
  },

  data: () => ({
    loading: true,

    project: {},
  }),

  computed: mapGetters(['board']),

  beforeRouteEnter (to, from, next) {
    next(async vm => {
      try {
        const project = await vm.getProjectByAlias(to.params.alias)
        vm.$title = project.name
        vm.project = project
        window.scrollTo({
          top: 0,
          behavior: 'instant',
        })
        if (vm.project.houseId) {
          await vm.fetchBoard(vm.project.houseId)
          vm.loading = false
        }
        next()
      } catch {
        next({ name: '404', replace: true })
      }
    })
  },

  methods: {
    ...mapActions(['getProjectByAlias', 'fetchBoard']),
    ...mapMutations(['setError']),
  },
}
</script>
