<template>
  <transition
    v-on:before-enter="beforeEnter"
    v-on:after-enter="afterEnter"
    v-on:before-leave="beforeLeave"
    v-on:after-leave="afterLeave"
  >
    <div
      v-if="show"
      class="offcanvas"
      :class="'offcanvas-' + position"
      tabindex="-1"
      role="dialog"
      aria-modal="true"
      aria-hidden="true"
    >
      <div class="offcanvas-header">
        <slot name="header">
          <h5
            v-if="title"
            class="offcanvas-title"
          >
            {{ title }}
          </h5>
        </slot>
        <AtomButton
          variant="close"
          class="text-reset"
          @click="close($event)"
        />
      </div>
      <div
        v-if="!!$slots.default"
        class="offcanvas-body"
      >
        <MoleculeLoading v-if="loading" />
        <slot v-else name="default" />
      </div>
    </div>
  </transition>
</template>

<script>
import { getClosestValidator, ScrollHelper } from '@/helpers'

import MoleculeLoading from '@/components/MoleculeLoading'
import AtomButton from '@/components/AtomButton'

export default {
  name: 'MoleculeModal',

  components: {
    AtomButton,
    MoleculeLoading,
  },

  props: {
    title: String,

    show: {
      type: Boolean,
      default: false,
    },

    position: {
      type: String,
      default: 'end',
      validator: value => ['start', 'end', 'top', 'bottom'].includes(value),
    },

    loading: {
      type: Boolean,
      default: true,
    },
  },

  data: () => ({
    transitionDuration: 150,
    backdrop: null,
  }),

  watch: {
    show (value) {
      if (!value) {
        this.resetValidator()
      } else {
        this.$emit('open')
      }
    },
  },

  beforeDestroy () {
    this.afterLeave()
  },

  methods: {
    close () {
      this.resetValidator()
      this.$emit('close')
    },

    resetValidator () {
      const validator = getClosestValidator(this)
      if (validator && typeof validator.$reset === 'function') {
        validator.$reset()
      }
    },

    beforeEnter () {
      if (!document) return
      this.backdrop = document.createElement('div')
      this.backdrop.classList.add('offcanvas-backdrop', 'fade')
      this.backdrop.addEventListener('click', this.close)
      document.body.appendChild(this.backdrop)

      this.$options.scrollHelper.hide()

      setTimeout(() => this.backdrop.classList.add('show'), this.transitionDuration)
    },

    afterEnter (el) {
      el.style.transform = 'none'
      el.style.visibility = 'visible'
    },

    beforeLeave (el) {
      el.style.transform = ''
    },

    afterLeave () {
      if (!document) return
      if (this.backdrop) {
        this.backdrop.classList.remove('show')
        setTimeout(() => {
          if (document.body.hasChildNodes(this.backdrop)) {
            this.backdrop.remove()
          }
        }, this.transitionDuration)
      }

      this.$options.scrollHelper.show()

      this.$emit('onClose')
    },
  },

  scrollHelper: new ScrollHelper(),
}
</script>
