<template>
  <div>
    <MoleculeCard :loading="loading">
      <template #header>
        <AtomButton
          @click="$router.push('/projects')"
          class="me-3"
          :title="$t('action.back')"
        >
          <arrow-left-icon />
        </AtomButton>
        <h2 class="card-title fs-h2">{{ title }}</h2>
      </template>
      <template #content>
        <MoleculeCard
          v-if="loading"
          body-class="board-placeholder"
        >
          <div
            v-for="placeholder, idx in 48"
            :key="idx"
            class="placeholder"
          >
            {{ properties }}
          </div>
        </MoleculeCard>
        <div
          v-else
          class="table-responsive board"
        >
          <table class="table card-table table-vcenter text-nowrap">
            <thead>
              <th class="w-1"></th>
              <th
                v-for="section in sectionNames"
                :key="section.number"
              >
                <span>{{ section.name }}</span>
              </th>
            </thead>
            <tbody>
              <tr
                v-for="floor in board"
                :key="floor.number"
              >
                <td><span>{{ floor.number }}</span></td>
                <td
                  v-for="section in floor.sections"
                  :key="section.number"
                >
                  <div class="d-flex align-items-center">
                    <MoleculeCard
                      v-for="flat, idx in section.cells"
                      :key="idx"
                      class="flat"
                      :class="{
                        active: flat.id && flat.status === 'AVAILABLE',
                        empty: !flat.id,
                        favorite: !!flat.favorite,
                      }"
                      :color="flat.id && flat.status === 'AVAILABLE' ? (theme === 'dark' ? 'teal' : 'teal-lt') : (theme === 'dark' ? 'azure-lt' : null)"
                      :border="!!flat.id && flat.status === 'AVAILABLE'"
                    >
                      <template
                        v-if="flat.id"
                        #content
                      >
                        <div
                          class="flat__wrapper"
                          @click.prevent="showProperty(flat)"
                        >
                          <div
                            v-if="flat.number"
                            class="flat__number"
                          >
                            {{ flat.number }}
                          </div>
                          <div
                            v-if="flat.price.value"
                            class="flat__price fs-h3 fw-bold"
                          >
                            <span v-if="flat.status === 'AVAILABLE'">
                              {{ flat.price.value | formatPrice }}
                            </span>
                            <span v-else>{{ $t(`property.status.${flat.status}`) }}</span>
                          </div>
                          <div class="flat__info">
                            <strong v-if="flat.area.area_total">{{ flat.area.area_total }} м²</strong>
                            <span v-if="flat.price.pricePerMeter && flat.status === 'AVAILABLE'"> - {{ flat.price.pricePerMeter | formatPrice }}/м²</span>
                          </div>
                          <div class="flat__info"><span v-if="flat.rooms_amount">{{ $tc('room.count', flat.rooms_amount) }}</span></div>
                        </div>
                        <button
                          v-if="flat.id && flat.status === 'AVAILABLE'"
                          class="flat__heart"
                          :class="{ tada: clicked === flat.id }"
                          @click.prevent="clickFavoriteHandler(flat)"
                        >
                          <heart-icon />
                        </button>
                      </template>
                    </MoleculeCard>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </template>
    </MoleculeCard>
    <MoleculeOffcanvas
      :loading="property.loading"
      :show="property.show"
      @close="property.show = false; property.data = null"
    >
      <template
        v-if="property.data"
        #header
      >
        <div class="d-flex align-items-center">
          <h5 class="offcanvas-title">{{ property.data.title }}</h5>
          <AtomStatus
            v-if="property.data.status"
            class="ms-3"
            :color="property.data.status === 'AVAILABLE' ? 'success' : null"
            variant="outline"
          >
            {{ $t(`property.status.${property.data.status}`) }}
          </AtomStatus>
        </div>
      </template>
      <MoleculeProperty
        v-if="property.data"
        :data="property.data"
        :is-favorite="property.favorite"
        :is-favorite-clicked="property.data.id === clicked"
        @clickFavorite="clickFavoriteHandler"
      >
        <template
          v-if="property.data.status === 'AVAILABLE'"
          #footer
        >
          <MoleculeShare
            :numbers="[property.data.number]"
            :text="$t('presentation.download')"
            :wide="true"
          />
        </template>
      </MoleculeProperty>
    </MoleculeOffcanvas>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'

import MoleculeOffcanvas from '@/components/MoleculeOffcanvas'
import MoleculeCard from '@/components/MoleculeCard'
import MoleculeProperty from '@/components/MoleculeProperty'
import MoleculeShare from '@/components/MoleculeShare'
import AtomButton from '@/components/AtomButton'
import AtomStatus from '@/components/AtomStatus'

export default {
  components: {
    MoleculeOffcanvas,
    MoleculeCard,
    MoleculeProperty,
    MoleculeShare,
    AtomButton,
    AtomStatus,
  },

  props: {
    loading: {
      type: Boolean,
      default: true,
    },

    title: {
      type: String,
    },

    properties: {
      type: Array,
      default: () => [],
    },

    floors: {
      type: Array,
      default: () => [],
    },

    sectionNames: {
      type: Array,
      default: () => [],
    },
  },

  data: () => ({
    property: {
      show: false,
      loading: true,
      data: null,
      favorite: false,
    },

    clicked: null,
  }),

  computed: {
    ...mapGetters(['favorites', 'theme']),

    board () {
      return this.floors.map(floor => ({
        ...floor,
        sections: floor.sections.map(section => ({
          ...section,
          cells: section.cells.map(cell => {
            let data = { id: false }

            if (cell.propertyId) {
              data = this.properties.find(property => cell.propertyId === property.id)
              data.favorite = this.favorites.some(item => item.id === cell.propertyId)
            }

            return data
          })
        }))
      }))
    },
  },

  async mounted () {
    await this.fetchFavorites()
  },

  methods: {
    ...mapActions(['fetchFavorites', 'processFavorite', 'getProperty']),
    ...mapMutations(['setError']),

    async clickFavoriteHandler (flat) {
      if (!flat || !flat.id || !flat.house_id) { return }

      this.clicked = flat.id
      await this.processFavorite({ id: flat.id, group: flat.house_id })
      this.clicked = null

      if (this.property.data) {
        this.property.favorite = this.favorites.some(item => item.id === flat.id)
      }
    },

    async showProperty (flat) {
      if (!flat || !flat.id) { return }

      try {
        this.property.loading = true
        this.property.show = true

        const property = await this.getProperty(flat.id)
        this.property.data = property
        this.property.favorite = this.favorites.some(item => item.id === flat.id)
      } catch (e) {
        this.setError(e)
      } finally {
        setTimeout(() => { this.property.loading = false }, 1000)
      }
    },
  },
}
</script>

<style lang="scss">
  @import "~bootstrap/scss/functions";
  @import '~@tabler/core/src/scss/variables';

  .flat {
    position: relative;
    display: inline-block;
    // color: $gray-500 !important;
    padding: .25rem 2.5rem .25rem .5rem;
    width: 200px;
    height: 67px;
    font-size: 0.85em;
    opacity: .5;
    background-color: $gray-100;

    &:not(.empty) {
      cursor: pointer;
    }

    &.active {
      opacity: 1;
      color: $dark !important;
    }

    .theme-dark & {
      color: $white !important;
    }

    +.flat {
      margin-left: .5rem;
    }

    &__number {
      position: absolute;
      top: .25rem;
      right: .25rem;
      width: 2.5rem;
      // height: 2rem;
      border-radius: $card-border-radius;
      background-color: $gray-100;
      font-weight: bold;
      display: flex;
      align-items: center;
      justify-content: center;
      user-select: none;
      font-size: 16px;

      .active & {
        background-color: $teal;
        color: $white;
      }

      .theme-dark & {
        background-color: rgba(255, 255, 255, 0);
      }

      &::before {
        content: '№';
        font-weight: normal;
        font-size: 10px;
        margin-right: 2px;
      }
    }

    .active &__info {
      color: $gray-600;

      .theme-dark & {
        color: inherit;
      }
    }

    &__heart {
      position: absolute;
      border: none;
      background: none;
      bottom: .25rem;
      right: .25rem;
      padding: 0;
      margin: 0;

      .icon {
        stroke: $gray-300;
        fill: $gray-200;

        .favorite & {
          stroke: $red;
          fill: $red;
        }
      }
    }
  }

  .board {
    padding-left: 35px;

    table {
      thead {
        th {
          background-color: transparent;

          span {
            position: sticky;
            left: 0;
          }
        }
      }

      tbody {
        border: none !important;

        tr {
          td {
            border: none;

            &:first-child {
              position: absolute;
              width: 35px;
              height: 83.34px;
              left: 0;
              top: auto;
              border-top-width: 1px;
              margin-top: -1px;
              display: flex;
              align-items: center;
              justify-content: center;
              background: $white;
              z-index: 5;

              .theme-dark & {
                background-color: $dark;
              }
            }
          }
        }
      }
    }

    &-placeholder {
      display: grid;
      grid-template-columns: repeat(6, 200px);
      gap: 1rem;
      width: 100%;
      overflow-x: auto;

      .placeholder {
        height: 67px;
      }
    }
  }
</style>
